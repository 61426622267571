import axios from 'axios';

export default axios.create({
baseURL:'https://games.millergroup.it/api/'
   //baseURL:'http://localhost:3000/api/'
});


//https://dynamic.millergroup.it/api/

//http://localhost:3000/api/